import { IconType } from '@fjordkraft/fjordkraft.component.library';
import { _getTextPlankPrefabTemplate } from '.';
import { Constants } from '../../../data';
import {
	AllowFeature,
	IsActiveCustomer,
	createString,
	getText,
	getParsedAddonStates,
	shouldServiceBeAvailable,
	hasServiceActivated,
} from '../../../services';
import { IHomePageData } from '../HomePageData';
import { IAddonData, ServiceStatus } from '../../../models';
import { IStatePlank } from '../../../components';
import { getSteddiStatus } from '../../ServicesPagesWrapper/ServicePage/Datahandling/UniqueServices';

export const ServicesPlank = (props: IHomePageData): IStatePlank | undefined => {
	const { epiChildren, translations, user, services } = props;
	const { installation } = user;
	const { customerServiceFeature } = services;

	if (
		AllowFeature(Constants.features.services, translations, services.user, customerServiceFeature) &&
		IsActiveCustomer(installation)
	) {
		return {
			promise: fetchServicesPlank(props, epiChildren),
		};
	}
};

const _getActiveServiceAmountText = (amount: number, translation: any) => {
	let activeText: string = '';

	if (amount === 0) {
		activeText = getText('plankServicesValueNone', translation);
	} else if (amount === 1) {
		activeText = createString(getText('plankServicesValue', translation), {
			amount: amount,
		});
	} else {
		activeText = createString(getText('plankServicesValuePlural', translation), {
			amount: amount,
		});
	}

	return activeText;
};

const fetchServicesPlank = async (props: IHomePageData, epiChildren: any) => {
	const { activeTheme, activeBrand, translations, services, user } = props;
	const { installation, userData } = user;
	const { GET, customerServiceFeature } = services;

	let addonCount: number = 0;
	if (epiChildren) {
		const [addonStates, steddiStatus] = await Promise.all([
			getParsedAddonStates(epiChildren, GET),
			getSteddiStatus(userData.accounts),
		]);
		if (addonStates) {
			addonStates.forEach((addon: IAddonData) => {
				let addonState: ServiceStatus | undefined;

				if (addon.page.data.servicePageId === 'forutsigbar') {
					addonState = steddiStatus;
				} else {
					addonState = addon.state[installation!.meterId]?.state;
				}

				if (addonState) {
					if (
						shouldServiceBeAvailable(addon.page.data, user, customerServiceFeature, addonState) &&
						hasServiceActivated(addonState)
					) {
						addonCount++;
					}
				}
			});
		}
	}

	return _getTextPlankPrefabTemplate({
		theme: activeTheme,
		brand: activeBrand,
		title: getText('plankServicesTitle', translations),
		description: getText('plankServicesDesc', translations),
		rightTitle: _getActiveServiceAmountText(addonCount, translations),
		link: Constants.paths.servicesPage,
		iconRight: IconType.ChevronRight,
	});
};
