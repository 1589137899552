import { IconType } from '@fjordkraft/fjordkraft.component.library';
import { IMSPlankWall, IPlankHouse } from '../../../../blocks';
import { IServiceBasePageData } from '../ServiceBasePageData';
import { IStatePlank } from '../../../../components';
import { IServicePage, ServiceStatus } from '../../../../models';
import { ITextPlankPrefab, MS_ButtonTemplate, getPlankPrefab } from '../../../../Prefabs';
import { IActionPlankPrefab } from '../../../../Prefabs/Planks/ActionPlankPrefab/ActionPlankPrefab';
import {
	showingDropdownOrder,
	createString,
	getServiceStatusColor,
	getSpecificAddonStateForInstallation,
	getText,
} from '../../../../services';
import { getSteddiWalls, getTrumfWalls } from './UniqueServices';

// ************************************
// PUBLIC
// ************************************

export const getServicePageHouseData = async (config: IServiceBasePageData): Promise<IPlankHouse | undefined> => {
	const { translations } = config;

	let house: IPlankHouse = {
		plankWalls: [],
	};

	switch (translations.servicePageId) {
		case 'forutsigbar':
			house.plankWalls = house.plankWalls.concat(await getSteddiWalls(config));
			break;
		case 'trumf':
			house.plankWalls = house.plankWalls.concat(await getTrumfWalls(config));
			break;

		default:
			house.plankWalls = house.plankWalls.concat(_getInstallationWalls(config));
	}

	return house;
};

interface IServiceActionPlank {
	title: string;
	desc: string;
	servicePage: IServicePage;
	status: ServiceStatus;
	desktopView: boolean;
	isGuest: boolean;
	onClick: (value: any) => void;
	bottom?: IActionPlankPrefab['bottom'];
}

export const getActionPlank = (config: IServiceActionPlank): IStatePlank => {
	const { title, desc, servicePage, status, onClick, desktopView, isGuest, bottom } = config;

	let statusValues = _getActionPlankStatusValues(status, servicePage);
	let active: boolean = status === 'ACTIVE' || status === 'ACTIVE_FUTURE';

	if (statusValues.showButton) {
		return getPlankPrefab('Action', {
			action: {
				disabled: isGuest,
				onClick,
				text: desktopView ? statusValues.value : undefined,
				icon: _getActionPlankIcon(desktopView, active),
			},
			actionButtonPadding: desktopView ? 'small' : 'default',
			left: {
				title,
				description: desc,
			},
			right: {
				template: MS_ButtonTemplate('Light', active ? 'secondary' : 'primary'),
			},
			bottom: bottom,
			actionIconPlacement: 'Right',
		} as IActionPlankPrefab);
	} else {
		return getPlankPrefab('Text', {
			left: {
				title,
				description: desc,
			},
			right: servicePage.allowOrder
				? {
						title: statusValues.value,
						customization: {
							title: {
								color: getServiceStatusColor(status),
							},
						},
				  }
				: undefined,
		} as ITextPlankPrefab);
	}
};

// ************************************
// PRIVATE :: Walls
// ************************************

const _getInstallationWalls = (config: IServiceBasePageData): IMSPlankWall[] => {
	const { installation } = config.user;

	let walls: IMSPlankWall[] = [];
	let planks: IStatePlank[] | undefined = _getInstallationPlanks(config);

	if (installation && planks && planks.length > 0) {
		walls.push({ planks });
	}

	return walls;
};

// ************************************
// PRIVATE :: Planks
// ************************************

const _getInstallationPlanks = (config: IServiceBasePageData): IStatePlank[] | undefined => {
	const { translations, addonStates, desktopView, onClickServiceHandling, user, relationship } = config;
	const { installation } = user;
	const { isGuest } = relationship;

	if (installation) {
		let planks: IStatePlank[] = [];
		let status: ServiceStatus =
			getSpecificAddonStateForInstallation(translations.productDefinitionId, installation.meterId, addonStates)
				?.state ?? 'INACTIVE';
		let title: string = installation.address.streetAddress ?? '';
		let desc: string = createString(getText('plankInstallationDesc', translations), {
			meterId: installation.meterId,
		});

		if (!showingDropdownOrder(config, status)) {
			planks.push(
				getActionPlank({
					status,
					title,
					desc,
					servicePage: translations,
					desktopView,
					isGuest,
					onClick: () => {
						onClickServiceHandling({
							installation,
							status,
							page: translations,
							addonStateResponse: addonStates,
						});
					},
				})
			);
		}

		if (planks.length > 0) {
			return planks;
		}
	}
};

// ************************************
// PRIVATE :: Handling
// ************************************

const _getActionPlankIcon = (desktopView: boolean, active: boolean): IconType => {
	if (desktopView) {
		return IconType.ExternalLinkThick;
	} else {
		return active ? IconType.MinusThin : IconType.PlusThin;
	}
};

const _getActionPlankStatusValues = (status: ServiceStatus, servicePage: IServicePage) => {
	let showButton: boolean = false;
	let value: string = '';

	switch (status) {
		case 'ACTIVE':
		case 'ACTIVE_FUTURE':
			value = getText('cancel', servicePage);
			showButton = true;
			break;
		case 'INACTIVE':
		case 'ORDER_CANCELLED_BY_CUSTOMER':
		case 'TERMINATED':
		case 'ORDER_FAILED':
			value = getText('order', servicePage);
			showButton = servicePage.allowOrder ?? true;
			break;
		case 'ACTIVATING':
		case 'TERMINATING':
		case 'ORDER_IN_PROGRESS':
		case 'ORDER_WAITING_FOR_CUSTOMER':
			value = getText('awaiting', servicePage);
			break;
	}

	return { showButton, value };
};
