import React from "react"
import { useApplicationDefaultContext } from "../../contexts";
import { AllowFeature } from "../../services";
import { Constants } from "../../data";

let initiated = false;

export const KindlyWrapper = (props: any) => {
    let def = useApplicationDefaultContext();
    if (!initiated && AllowFeature(Constants.features.chatBot, def.defaultProps.epiChildren.HeadlessHomePageType.data, def.defaultProps.user, false)) {
        initiated = true;
        const kindlyAuthTokenUrl = `${process.env.REACT_APP_API_URL}/v1/${process.env.REACT_APP_API_BRAND}/Customers/kindlyJwt`;
        const getAuthToken = async function (chatId: string) {
            var response = await fetch(`${kindlyAuthTokenUrl}?chatId=${chatId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${def.defaultProps.services.token}`
                }
            });
            var data = await response.text();
            window.kindlyChat.setNewContext({ token: data });
            window.kindlyChat.setNewContext({ userLoggedIn: true });
            return data;
        }
        window.kindlyOptions = {
            getAuthToken: getAuthToken
        };
        var script = document.createElement('script');
        script.id = 'kindly-chat';
        script.src = 'https://chat.kindlycdn.com/kindly-chat.js';
        script.dataset.botKey = "f612cd01-3da1-48af-93e5-438fe3833866";
        script.dataset.shadowDom = "";
        document.head.appendChild(script)
        document.head.appendChild(document.createElement('script')).src = "https://cust-fjordkraft.web.app/scripts/handover.js";
    }
    return <>{props.children}</>
}