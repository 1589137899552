import { IconType } from '@fjordkraft/fjordkraft.component.library';
import { IPointPlankPrefab, SteddiPlankTemplate, getPlankPrefab } from '../../../../../Prefabs';
import { IMSPlankWall } from '../../../../../blocks';
import { ICustomerAccountInformation, ServiceStatus } from '../../../../../models';
import {
	AllowFeature,
	HomePageData,
	createString,
	getAddressesBasedOnAgreement,
	getText,
	redirectToSteddiPaymentFreeUrl,
} from '../../../../../services';
import { Constants } from '../../../../../data';
import { IServiceBasePageData } from '../../ServiceBasePageData';
import { typedGetRequest } from '../../../../../contexts';
import { addMonths, format } from 'date-fns';
import { IActionPlankPrefab } from '../../../../../Prefabs/Planks/ActionPlankPrefab/ActionPlankPrefab';
import { IStatePlank } from '../../../../../components';
import { IServiceOrderOrCancel } from '../../../../../modals';
import { getActionPlank } from '../ServicePageDataHouse';

// ************************************
// Predictable Payment planks
// ************************************

export const getSteddiWalls = async (config: IServiceBasePageData): Promise<IMSPlankWall[]> => {
	const { user } = config;
	const { userData } = user;

	let walls: IMSPlankWall[] = [];

	let steddiWalls: IMSPlankWall[] | undefined = getPredictablePaymentPlanks(config);

	if (steddiWalls) {
		walls = walls.concat(steddiWalls);
	}

	if (userData.accounts?.length > 0) {
		walls.push({ planks: await _getSteddiAccountPlanks(config) });
	}

	return walls;
};

export const getPredictablePaymentPlanks = (config: IServiceBasePageData): IMSPlankWall[] => {
	const { user, services, translations, activeTheme, epiChildren } = config;
	const { userData } = user;
	const { GET, customerServiceFeature } = services;

	let walls: IMSPlankWall[] = [];

	if (userData.accounts.length > 0) {
		userData.accounts.forEach((account: ICustomerAccountInformation) => {
			let accountWall: IMSPlankWall = {
				planks: [],
			};

			if (account.steddiInfo) {
				accountWall.planks.push(
					getPlankPrefab('Point', {
						brand: 'brand-steddi',
						template: SteddiPlankTemplate(activeTheme ?? 'Light', 'top'),
						useDecimals: true,
						left: {
							title: getText('plankOverviewTitle', translations),
							description: createString(getText('plankOverviewDesc', translations), {
								amount: account.steddiInfo.monthlyAmount,
							}),
						},
						right: {
							description: createString(getText('plankOverviewTitleValue', translations), {
								accountNumber: account.accountId,
							}),
						},
						points: account.steddiInfo.balance,
						pointsLabel: getText('currency', translations),
					} as IPointPlankPrefab)
				);
				accountWall.planks.push(
					getPlankPrefab('Text', {
						left: {
							title: getText('plankLastTransactionsTitle', translations),
							description: getText('plankLastTransactionsDesc', translations),
						},
						right: {
							icon: IconType.ChevronRight,
						},
						action: {
							link: `${Constants.paths.predictablePaymentTransactions}/${account.accountId}`,
						},
					})
				);

				if (
					AllowFeature(
						Constants.features.steddiPaymentFreeMonth,
						HomePageData(epiChildren),
						user,
						customerServiceFeature
					)
				) {
					accountWall.planks.push(
						getPlankPrefab('Text', {
							left: {
								title: getText('plankPaymentFreeTitle', translations),
							},
							right: {
								icon: IconType.ExternalLinkThick,
							},
							action: {
								onClick: async () => {
									await redirectToSteddiPaymentFreeUrl(account.accountId, GET);
								},
							},
						})
					);
				}
			}

			if (accountWall.planks.length > 0) {
				walls.push(accountWall);
			}
		});
	}

	return walls;
};

const _getSteddiAccountPlanks = async (config: IServiceBasePageData): Promise<IStatePlank[]> => {
	const { user, translations, desktopView, onClickServiceHandling, relationship, services } = config;
	const { userData } = user;
	const { isGuest } = relationship;

	let planks: IStatePlank[] = [];

	if (userData && translations) {
		for (const account of userData.accounts) {
			const desc: string = getAddressesBasedOnAgreement({ userData, accountId: account.accountId });
			const title: string = createString(getText('plankAccountTitle', translations), {
				accountNumber: account.accountId,
			});
			const status: ServiceStatus = await getSteddiStatus(
				userData.accounts,
				account.accountId,
				services.GETTYPED
			);

			let bottom: IActionPlankPrefab['bottom'];

			if (status == 'ACTIVE_FUTURE') {
				bottom = {
					description: createString(getText('plankAccountActivating', translations), {
						date: format(getSteddiStartDate(), 'dd.MM.yyyy'),
					}),
				};
			}

			const actionPlank = getActionPlank({
				title,
				desc,
				bottom,
				servicePage: translations,
				status,
				desktopView,
				isGuest,
				onClick: () => {
					onClickServiceHandling({
						account,
						page: translations,
						status,
					} as IServiceOrderOrCancel);
				},
			});

			if (actionPlank) planks.push(actionPlank);
		}
	}

	return planks;
};

/**
 * Returns Steddi (Forutsigbar Betaling) status.
 * @param accounts
 * @param accountId
 * @param GETTYPED
 * @returns "INACTIVE", "ACTIVE", "ACTIVATING", "ACTIVE_FUTURE", "ORDER_IN_PROGRESS", "ORDER_FAILED"
 */
export const getSteddiStatus = async (
	accounts: ICustomerAccountInformation[],
	accountId?: string,
	GETTYPED?: typedGetRequest
): Promise<ServiceStatus> => {
	// First we look in customer context if accounts include steddiInfo.
	// If an accountId is specified we check that, otherwise we return 'ACTIVE' if *any* account has steddi.
	const steddiAccount = accounts.find((acc) => acc.steddiInfo && (accountId ? accountId == acc.accountId : true));

	if (steddiAccount) return 'ACTIVE';

	// Else we request the backend to fetch it from the steddi api, and return the first in the list if an accountId is not specified.
	if (GETTYPED) {
		const resp = await GETTYPED<{ accountId: string; status: FetchableSteddiStatuses }[]>('steddi/orders');

		if (resp.callState == 'success' && resp.data?.length) {
			if (!accountId) {
				return resp?.data[0].status as ServiceStatus;
			} else {
				const fetchedSteddiStatus = resp.data?.find(
					(accountStatus) => accountStatus && (accountId ? accountId == accountStatus.accountId : true)
				)?.status;

				if (fetchedSteddiStatus == 'ORDER_COMPLETED') {
					// If the steddi api returns a completed order, but we can not find it in customer.accounts,
					// then the customers account has been created in the steddi system, but not yet connected,
					// which will happen on the first of next month.
					return 'ACTIVE_FUTURE';
				}

				if (fetchedSteddiStatus == 'ORDER_FAILED') {
					// If the steddi api returns a failed order, then Elmera is actively working on fixing it
					// and we return the order as being "in progress":
					return 'ORDER_IN_PROGRESS';
				}

				if (fetchedSteddiStatus) return fetchedSteddiStatus;
			}
		}
	}

	return 'INACTIVE';
};

/**
 * Possible statuses from the "api/steddi/orders" endpoint.
 * Will always be a subset of ServiceStatus.
 */
export type FetchableSteddiStatuses = 'INACTIVE' | 'ORDER_COMPLETED' | 'ORDER_IN_PROGRESS' | 'ORDER_FAILED';

/**
 * If the steddi status is "ACTIVE_FUTURE" it *should always* be activated on the first of next month:
 * @returns
 */
export const getSteddiStartDate = () => {
	const today = new Date();
	const firstOfNextMonth = addMonths(new Date(today.getFullYear(), today.getMonth(), 1), 1);
	return firstOfNextMonth;
};
