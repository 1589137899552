import React from 'react';
import {
    BaseComponent,
    ContentGrid,
    IAction,
    IBaseComponent,
    Icon,
    IconType,
    IImage,
    Image,
    StyleGrid,
    Text
} from "@fjordkraft/fjordkraft.component.library";
import classNames from "classnames";
import {
    CommercialTemplate,
    h4TextPrefab,
    mediumTextPrefab,
    paragraphTextPrefab
} from '../../Prefabs';
import {MSRichText} from '../MSRichText/MSRichText';
import "./CommercialBanner.scss";
import {Link, useNavigate} from "react-router-dom";
import {useDefaultPageContext} from "../../contexts";

export interface ICommercialBanner extends IBaseComponent {
    action?: IAction;
    title?: string;
    description?: string;
    image?: IImage;
    priority: number;
    desktopView: boolean;
    link?: string;
    extra?: {
        title: string;
        description: string;
    }
}

export const CommercialBanner = (props: ICommercialBanner) => {

    // ************************************
    // Properties
    // ************************************


    const {
        id,
        brand,
        theme = 'Light',
        className,
        link,
        title,
        description,
        image,
        extra,
        template = CommercialTemplate(theme),
    } = props;
    const classPrefix = 'commercial-banner';

    const { setContentLoading } = useDefaultPageContext();
    const navigate = useNavigate();

    // ************************************
    // Helpers
    // ************************************

    // ************************************
    // Render
    // ************************************


    return (
        <Link
            className={classNames(`${classPrefix}`, {
                [`${className}`]: className,
            })}
            to={'..'}
            onClick={(e) => {
                e.preventDefault();
                setContentLoading(true);
                navigate(link ?? '/');
            }}
        >
            <BaseComponent
                id={id}
                brand={brand}
                theme={theme}
                template={template}
                className={classNames(classPrefix, {
                    [`${className}`]: className
                })}
            >
                <StyleGrid
                    className={`${classPrefix}__top`}
                    direction={'row'}
                    alignment='top-left'
                    wrap={false}
                >
                    <ContentGrid
                        className={classNames(`${classPrefix}__top__header`, {
                            [`${classPrefix}__top__header--clamp`]: image
                        })}
                        direction='column'
                        alignment='top-left'
                        tagType={'section'}
                        boxSizing={'border-box'}
                    >
                        {(title) &&
                            <ContentGrid
                                className={`${classPrefix}__top__header__nav`}
                                direction='row'
                                alignment='top-left'
                                tagType={'nav'}
                                gap={1}
                            >
                                <Text
                                    className={`${classPrefix}__top__header__nav__title`}
                                    palette={template.palette}
                                    {...mediumTextPrefab({brand: brand, theme: theme})}
                                >
                                    {title}
                                    <Icon
                                        className={`${classPrefix}__top__header__nav__arrow`}
                                        palette={template.palette}
                                        theme={theme}
                                        brand={brand}
                                        type={IconType.ArrowRight}/>
                                </Text>

                            </ContentGrid>
                        }
                        {description &&
                            <MSRichText
                                className={`${classPrefix}__top__header__desc`}
                                palette={template.palette}
                                customization={{type: "p", color: template.palette.text?.color}}
                                theme={theme}
                                text={description}
                                alignment='lawful'
                            />
                        }
                    </ContentGrid>
                    {image &&
                        <Image
                            {...image}
                            className={`${classPrefix}__top__image`}
                            fit={'cover'}
                            scalingBy={'height'}
                        />
                    }
                </StyleGrid>
                {extra &&
                    <StyleGrid
                        direction='row'
                        alignment='top-left'
                        className={`${classPrefix}__right__bottom`}
                        boxSizing={'border-box'}
                        spaceBetween
                    >
                        <ContentGrid
                            className={`${classPrefix}__right__extra`}
                            direction='column'
                            alignment='top-left'
                            tagType={'section'}
                            boxSizing={'border-box'}
                        >
                            <Text
                                {...h4TextPrefab()}
                                palette={template.palette}
                            >
                                {extra.title}
                            </Text>
                            <Text
                                {...paragraphTextPrefab()}
                                palette={template.palette}
                                faded
                            >
                                {extra.description}
                            </Text>
                        </ContentGrid>
                    </StyleGrid>
                }
            </BaseComponent>
        </Link>
    );
}